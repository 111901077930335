import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import usePrevious from '~/hooks/usePrevious';
import GridContainer from '~/components/Commons/Grid/GridContainer';
import GridItem from '~/components/Commons/Grid/GridItem';
import errorPageStyles from '~/views/Pages/errorPageStyles';
import Button from '~/components/Commons/Button/index';
import CustomLink from '~/components/Commons/Link/Link';
import { Box, Typography } from '@mui/material';

type Props = {
  resetError?: () => void;
  error?: unknown;
};

function ErrorPage({ resetError = undefined, error = undefined }: Props) {
  const location = useLocation();
  const previousPathname = usePrevious(location.pathname);
  useEffect(() => {
    if (previousPathname && previousPathname !== location.pathname) {
      resetError?.();
    }
  }, [resetError, previousPathname, location.pathname]);

  let is500Error = false;
  if (
    error &&
    typeof error === 'object' &&
    'response' in error &&
    error.response instanceof Response
  ) {
    is500Error = error?.response?.status >= 500;
  }

  return (
    <Box sx={errorPageStyles.contentCenter}>
      <GridContainer>
        <GridItem md={12}>
          {is500Error ? (
            <>
              <Typography variant="h1" sx={errorPageStyles.title}>
                500
              </Typography>
              <Typography variant="h2" sx={errorPageStyles.subTitle}>
                It looks like we&apos;re having technical difficulties
              </Typography>
              <Typography variant="h3" sx={errorPageStyles.description}>
                We&apos;re working to fix the problem as quickly as possible. Please try again
                later.
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h1" sx={errorPageStyles.title}>
                500
              </Typography>
              <Typography variant="h2" sx={errorPageStyles.subTitle}>
                Oooops, there was an error!
              </Typography>
              <Typography variant="h3" sx={errorPageStyles.description}>
                This has been reported to the administrator and we will fix it ASAP
              </Typography>
              <CustomLink to="/">
                <Button color="primary" onClick={() => null}>
                  Back to home page
                </Button>
              </CustomLink>
            </>
          )}
        </GridItem>
      </GridContainer>
    </Box>
  );
}

export default ErrorPage;
