import React from 'react';

// core components
import GridContainer from '~/components/Commons/Grid/GridContainer';
import GridItem from '~/components/Commons/Grid/GridItem';

import errorPageStyles from '~/views/Pages/errorPageStyles';
import useSession from '~/hooks/useSession/useSession';
import { Box, Typography } from '@mui/material';

function NoSessionPage() {
  const { logout } = useSession();

  return (
    <Box sx={errorPageStyles.contentCenter}>
      <GridContainer>
        <GridItem md={12}>
          <Typography variant="h2" sx={errorPageStyles.subTitle}>
            Session expired or no longer valid.
          </Typography>

          <br />
          <Box
            role="link"
            tabIndex={-1}
            title="Login page"
            onClick={() => logout(0)}
            sx={{
              color: 'info.main',
              fontSize: 20,
              fontWeight: 'bold',
              cursor: 'pointer',
              '&:hover': {
                color: 'info.light',
              },
            }}
          >
            Go to login page
          </Box>
        </GridItem>
      </GridContainer>
    </Box>
  );
}

export default NoSessionPage;
