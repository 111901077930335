import { Theme } from '@mui/material';

const errorPageStyles = {
  contentCenter: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: '3',
    transform: 'translate(-50%,-50%)',
    textAlign: 'center',
    padding: '0 15px',
    width: '100%',
    maxWidth: '880px',
    backgroundColor: (theme: Theme) => theme.palette?.secondary.main,
  },
  title: {
    fontSize: '10em',
    color: (theme: Theme) => theme.palette?.primary.light,
    letterSpacing: '14px',
    fontWeight: '700',
    margin: '10px',
  },
  titleSmall: {
    fontSize: '7em',
    color: (theme: Theme) => theme.palette?.primary.light,
    fontWeight: '700',
  },
  subTitle: {
    color: (theme: Theme) => theme.palette?.primary.light,
    fontSize: '2rem',
    marginTop: '0',
    marginBottom: '8px',
  },
  description: {
    fontSize: '1.125rem',
    marginTop: '0',
    marginBottom: '8px',
  },
} as const;

export default errorPageStyles;
