/* eslint-disable react/jsx-no-bind */
import * as React from 'react';
import * as Sentry from '@sentry/react';
import sidebarRoutes from '~/routes';

import { Outlet, useParams } from 'react-router-dom';

// Custom components
import AdminNavbar from '~/components/Commons/Navbars/AdminNavbar';
import Sidebar from '~/components/Commons/Sidebar/Sidebar';
import ErrorBoundaryPage from '~/views/Pages/ErrorBoundaryPage';
import DianomiSpinner from '~/components/Commons/DianomiSpinner/DianomiSpinner';

import { Box } from '@mui/material';

import adminStyle from '~/layouts/Admin/adminStyle';
// Context
// import useNotifications from '~/context/Notifications';
import useMiniMenu from '~/context/useMiniMenu';
import useClientInfo, { useClientInfoIsLoading } from '~/context/ClientInfo/useClientInfo';

// Services
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import useWindowSize from '~/hooks/useWindowSize';

import { getActiveRoute, setRoutesPermissions } from './RoutePermissions';

export default function Admin() {
  const { accountId, accountType } = useParams();
  const { isOpen, toggle } = useMiniMenu();
  const { isMobile } = useWindowSize();

  const clientInfo = useClientInfo();
  const isLoading = useClientInfoIsLoading();

  const processedRoutes = React.useMemo(
    () => setRoutesPermissions(sidebarRoutes, clientInfo),
    [clientInfo],
  );
  return (
    <>
      {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}
      <Box sx={adminStyle.root}>
        <Box sx={{ gridArea: 'header', position: 'sticky', top: 0, zIndex: 3000 }}>
          {!isLoading && clientInfo && clientInfo.id.toString() === accountId ? (
            <AdminNavbar
              sidebarMinimize={toggle}
              handleDrawerToggle={toggle}
              activeRoute={getActiveRoute(processedRoutes)}
              mobileNavOpen={isOpen && isMobile}
              showAccountSwitcher={!!accountId}
            />
          ) : (
            <AdminNavbar isAuthBar />
          )}
        </Box>
        {processedRoutes.length > 0 && accountId && clientInfo.id.toString() === accountId && (
          <Box sx={{ gridArea: 'sidebar' }}>
            <Sidebar
              key={clientInfo.id}
              routes={clientInfo ? processedRoutes : []}
              basePath={`/${accountType}/${accountId}`}
            />
          </Box>
        )}
        <Box sx={[adminStyle.mainPanel, { gridArea: 'main' }]}>
          <DianomiSpinner loading={isLoading}>
            <Sentry.ErrorBoundary
              // eslint-disable-next-line react/jsx-props-no-spreading
              fallback={(props) => <ErrorBoundaryPage {...props} />}
            >
              <React.Suspense fallback={<DianomiSpinner loading delay={500} />}>
                <Outlet />
              </React.Suspense>
            </Sentry.ErrorBoundary>
          </DianomiSpinner>
        </Box>
      </Box>
    </>
  );
}
