import { grey, blue } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import Nexa600 from '~/assets/fonts/Fontfabric-Nexa_Bold.otf';
import Nexa400 from '~/assets/fonts/Fontfabric-Nexa_Book.otf';
import NexaLight from '~/assets/fonts/Fontfabric-Nexa_Light.otf';

export const hexToRgb = (value: string) => {
  let input = value;
  if (!input) return input;
  input = input.replace('#', '');
  const hexRegex = /[0-9A-Fa-f]/g;
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error('input is not a valid hex color.');
  }
  if (input.length === 3) {
    const first = input[0];
    const second = input[1];
    const last = input[2];
    input = first + first + second + second + last + last;
  }
  input = input.toUpperCase();
  const first = input[0] + input[1];
  const second = input[2] + input[3];
  const last = input[4] + input[5];
  return `${parseInt(first, 16)}, ${parseInt(second, 16)}, ${parseInt(last, 16)}`;
};

const primaryMain = '#181919';
const secondaryMain = '#ffffff';
const errorMain = '#f44336';
const warningMain = '#ff9800';
const successMain = '#4caf50';
const infoMain = '#2196f3';

/**
 * brand colors:
 * Dark red: #651704
 * Light grey: #c1ceda
 * Dark blue: #212a51
 * Green #52a08d
 * Orange: #ec864e
 * Blue: #1263a1
 * Light brown: #bdac94
 * Yellow: #ffc64b
 */

const headerBorder = '1px solid #ebebeb';
const defaultBorder = `1px solid ${grey[400]}`;

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: primaryMain,
    },
    secondary: {
      main: secondaryMain,
    },
    error: {
      main: errorMain,
    },
    warning: {
      main: warningMain,
    },
    success: {
      main: successMain,
    },
    info: {
      main: infoMain,
      light: '#85C6F9',
      dark: '#004BD6',
    },
    pubDashboardComputer: {
      main: '#7A473B',
    },
    pubDashboardMobile: {
      main: '#326257',
    },
    pubDashboardTablet: {
      main: '#1263A1',
    },
    pubDashboardApp: {
      main: '#EC864E',
    },
    pubDashboardApple: {
      main: '#D7970B',
    },
    pubDashboardNewsletter: {
      main: '#328297',
    },
    pubDashboardSdk: {
      main: '#1263A1',
    },
    pubDashboardViewability: {
      main: '#C8D3DF',
    },
    pubDashboardAmp: {
      main: '#7E5E18',
    },
  },
  typography: {
    fontFamily: ['Nexa', 'Source Sans Pro', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    // Reflects the HTML headings sizes
    h1: {
      fontWeight: 'bold',
      fontSize: '3rem',
    },
    h2: {
      fontWeight: 'bold',
      fontSize: '2.5rem',
    },
    h3: {
      fontWeight: 'bold',
      fontSize: '2rem',
    },
    h4: {
      fontWeight: 'bold',
      fontSize: '1.75rem',
    },
    h5: {
      fontWeight: 'bold',
      fontSize: '1.5rem',
    },
    h6: {
      fontWeight: 'bold',
      fontSize: '1.25rem',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Nexa';
          src: url(${NexaLight}) format('opentype');
        }
        @font-face {
          font-family: 'Nexa';
          font-weight: 400;
          src: url(${Nexa400}) format('opentype');
        },
        @font-face {
          font-family: 'Nexa';
          font-weight: 600;
          src: url(${Nexa600}) format('opentype');
        },
      `,
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'success',
        size: 'small',
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {},
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          zIndex: 5000,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .Mui-error': {
            color: grey[700],
            '&. MuiFormLabel.root': {
              color: grey[700],
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&. Mui-disabled': {
            color: grey[700],
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '& .MuiFormControlLabel-label': {
            color: grey[700],
          },
          '&.Mui-disabled': {
            '& .MuiFormControlLabel-label': {
              color: grey[700],
            },
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            color: '#000000',
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          // sorry for the magic number - there, there
          maxHeight: 'calc(100vh - 320px) !important',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'collapse',
          maxHeight: 'calc(100vh - 300px)',
          overflow: 'unset',
          '& tr:nth-of-type(even)': {
            backgroundColor: '#f5f5f5',
          },
          '& td': {
            borderRight: defaultBorder,
            '&:last-of-type': {
              borderRight: 'none',
            },
            maxWidth: '300px',
            overflowWrap: 'break-word',
            whiteSpace: 'unset',
            overflow: 'unset',
          },
          '& th': {
            borderRight: defaultBorder,
            '&:last-of-type': {
              borderRight: 'none',
            },
            backgroundColor: '#f5f5f5',
            padding: '4px',
            '& .MuiTableSortLabel-root': {
              height: '0',
              width: '0',
              opacity: 0,
              transition: 'all 0.3s ease',
              '&.Mui-active': {
                opacity: 1,
                height: 'unset',
                width: 'unset',
                transition: 'all 0.3s ease',
              },
            },
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          '&.MuiModal-root': {
            zIndex: 3000,
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          h5: 'h5',
          h6: 'h6',
        },
      },
    },
  },
  defaultStyles: {
    border: defaultBorder,
    inputStyles: {
      fontFamily: ['Nexa', 'Source Sans Pro', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      border: defaultBorder,
      borderRadius: '4px',
      color: 'rgba(0, 0, 0, 0.87)',
      padding: '8px 10px',
      display: 'flex',
      flexGrow: 1,
      fontSize: '1rem',
      '&:focus': {
        borderColor: blue[800],
      },
      '&[disabled]': {
        background: grey[100],
      },
    },
    drawerWidth: 260,
    drawerWidthMini: 80,
    transition: 'all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)',
    boxShadow: {
      border: '0',
      borderRadius: '3px',
      boxShadow: `0 10px 20px -12px rgba(${hexToRgb(
        primaryMain,
      )}, 0.42), 0 3px 20px 0px rgba(${hexToRgb(
        primaryMain,
      )}, 0.12), 0 8px 10px -5px rgba(${hexToRgb(primaryMain)}, 0.2)`,
      padding: '10px 0',
    },
    scrollBar: {
      '&::-webkit-scrollbar': {
        backgroundColor: '#00000012',
        width: '6px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#3c3c3c8f',
        borderRadius: '90px',
      },
    },
    header: {
      backgroundColor: '#f8f8f8cc',
      borderBottom: headerBorder,
      borderLeft: headerBorder,
    },
    errorContainer: {
      padding: '0px 14px',
      fontSize: '11px',
      margin: '4px 0px 0px',
      borderRadius: '5px',
      backgroundColor: '#ff9292',
      color: '#000000',
      whiteSpace: 'normal',
    },
    warningContainer: {
      padding: '0px 14px',
      fontSize: '11px',
      margin: '4px 0px 0px',
      borderRadius: '5px',
      backgroundColor: '#fce3be',
      color: '#000000',
      whiteSpace: 'normal',
    },
  },
});

export default theme;
