/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-use-before-define */
import { QueryFunction, useQuery } from '@tanstack/react-query';
import { ErrorObjectString, TruthyNumber } from '~/utils/typescriptHelpers';
import { customFetch } from '~/utils/customFetch';
import useSessionPlQuery from '../useSessionPlQuery';

export type ClientPlData = {
  logo: string | null;
  timezone: string;
  timezoneActive: boolean;
  name: string;
  children?: Children;
  logins: Logins;
  targeting: Targeting;
  parent_client_id: number | null;
  billing_type: 'Invoice' | 'Credit Card' | null | (string & {});
  /**
   * Publisher O&O is for publishers to have a client account to serve their own ads.
   * normally they would pay 0 and have no budget set.
   */
  type: 'Publisher O&O' | (string & {}) | null;
  billing_capped: TruthyNumber | null;
  billing_terms_amount: number | null;
  suspensionReason: 'Dispute' | 'Arrears' | (string & {}) | null;
  // 'C' is normal status, 'Dispute' is when the client is disputing the invoice.
  status: 'C' | 'Dispute' | (string & {});
};

export type Children = Record<string, Child>;
type Child = {
  logo: string | null;
  name: string;
  status: string;
  id: number;
};

type Logins = Record<string, Login>;
type Login = {
  permission_campaign: 0 | 1 | 2;
  permission_invoices: TruthyNumber;
  username: string | null;
};

interface Targeting {
  audiences: Audiences;
  partners: Partners;
  geo: Geo;
}

interface Audiences {
  exclude_audience_ids: string[];
  include_audience_ids: string[];
}

interface Partners {
  include_partner_ids: string[];
  standard: boolean | null;
  exclude_partner_ids: string[];
  all: boolean | null;
}

interface Geo {
  include_region_ids: string[];
  exclude_region_ids: string[];
}

type ResponseData = ClientPlData | ErrorObjectString;

async function getClientInfo(id: number | string | null = null) {
  let requestOptions = {};

  requestOptions = {
    useNodeEndpoint: true,
  };

  const { data } = await customFetch.get<ResponseData>(
    `/authenticated/client/${id}`,
    requestOptions,
  );
  if ('error' in data) {
    throw new Error(data.error);
  }
  return data;
}

type ReturnData = Prettify<
  {
    isParent: boolean;
    isChild: boolean;
  } & ClientPlData
>;

const handleGetClientInfo: QueryFunction<
  ReturnData,
  ReturnType<typeof getClientPlQueryKey>
> = async ({ queryKey }) => {
  const clientData = await getClientInfo(queryKey[2]);
  const returnData = {
    ...clientData,
    isParent: !!clientData.children,
    isChild: clientData.parent_client_id !== null,
  };
  return returnData;
};

export const getClientPlQueryKey = ({
  id,
  isPartner,
}: {
  id?: number | string;
  isPartner?: boolean | undefined;
}) => ['client.pl', isPartner, String(id)] as const;
export type ResolvedClientPlData = Awaited<ReturnType<typeof handleGetClientInfo>>;
export default function useClientPlQuery<TData = ResolvedClientPlData>(
  select?: (data: ResolvedClientPlData) => TData,
) {
  const { data: sessionData } = useSessionPlQuery();
  const query = useQuery({
    queryKey: getClientPlQueryKey({
      id: sessionData?.id,
      isPartner: sessionData?.isPartner,
    }),
    queryFn: handleGetClientInfo,
    select,
    refetchOnWindowFocus: false,
    enabled: !!sessionData && sessionData?.isPartner === false,
  });
  return query;
}
