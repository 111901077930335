const adminNavbarLinksStyle = {
  navbarWrapper: {
    display: 'flex',
    position: 'relative',
    width: 'auto',
    zIndex: '4',
  },
  linkText: {
    zIndex: '4',
    padding: '3px 0px',
    fontSize: '11px',
    textTransform: 'none',
    color: 'white',
    '&:hover': {
      color: 'lightBlue',
    },
  },
  managerClasses: (theme) => ({
    [theme.breakpoints?.up('md')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingRight: '25px',
      marginTop: '5px',
    },
  }),
  markdown: {
    fontSize: '1em',

    maxHeight: '70vh',
    '@media screen and (min-height: 700px)': { maxHeight: '50vh' },
  },
  headerLinksSvg: {
    color: '#A7AAB6',
    width: '20px !important',
    height: '20px !important',
    marginLeft: '10px',
  },

  sessionsWrapper: {
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginRight: '20px',
  },
  activeSessions: {
    color: 'white',
  },
  activeSessionsWarn: {
    color: 'red',
  },
  sessionTableWrapper: {
    maxWidth: '700px',
    textAlign: 'center',
    margin: '25px auto',
    border: '1px solid #ccc',
  },
  whatsNewNotification: {
    textAlign: 'center',
  },
  clickHere: {
    cursor: 'pointer',
    '&:hover': {
      color: 'darkblue',
    },
  },
};

export default adminNavbarLinksStyle;
