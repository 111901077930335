import React from 'react';
import GridContainer from '~/components/Commons/Grid/GridContainer';
import GridItem from '~/components/Commons/Grid/GridItem';

import errorPageStyles from '~/views/Pages/errorPageStyles';
import CustomLink from '~/components/Commons/Link/Link';
import { Box, Typography } from '@mui/material';

function ErrorPage() {
  return (
    <Box sx={errorPageStyles.contentCenter}>
      <GridContainer>
        <GridItem md={12}>
          <Typography variant="h1" sx={errorPageStyles.title}>
            404
          </Typography>
          <Typography variant="h3" sx={errorPageStyles.subTitle}>
            Page not found :(
          </Typography>
          <Typography variant="h4" sx={errorPageStyles.description}>
            Ooooups! Looks like you got lost.
          </Typography>
          <CustomLink to="/">Click here to go home</CustomLink>
        </GridItem>
      </GridContainer>
    </Box>
  );
}

export default ErrorPage;
