/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Grid, { GridProps } from '@mui/material/Grid';

type AllowedGridProps = Pick<
  GridProps,
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | 'spacing'
  | 'direction'
  | 'justifyContent'
  | 'alignItems'
  | 'style'
  | 'sx'
>;

interface GridItemProps extends AllowedGridProps {
  children?: React.ReactNode;
}

function GridItem({
  children,
  sx,
  xs,
  sm,
  md,
  lg,
  xl,
  spacing,
  direction,
  justifyContent,
  alignItems,
  style,
}: GridItemProps) {
  return (
    <Grid
      item
      sx={sx}
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      spacing={spacing}
      direction={direction}
      justifyContent={justifyContent}
      alignItems={alignItems}
      style={style}
    >
      {children}
    </Grid>
  );
}

export default GridItem;
