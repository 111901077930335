import * as Sentry from '@sentry/react';
import { ClientPlData } from '~/hooks/queries/useClientPlQuery';
import { GenieNavPlData } from '~/hooks/queries/useGenieNavQuery';
import { NavPermissions } from '~/hooks/queries/useSessionPlQuery/types';
import { perms } from '~/routes';
import { SessionPlQueryReturnData } from '~/services/ClientServiceTS';
import { hasOwnProp } from '~/utils/utils';
import { isPlainObject } from './Admin.utils';

export type Perms = (typeof perms)[keyof typeof perms] | '*';

export type Route = {
  path: string;
  name: string;
  icon: string;
  perm: Perms;
  live: boolean;
  maintainParams: boolean;
  collapse?: undefined;
  views: Route[] | ((viewData: unknown) => Route[]);
  exact?: undefined;
  state?: undefined;
  viewsProp?: undefined;
  accountType?: undefined;
  allowed?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ClientInfoPermissions = Record<string, any>;

type CollatedClientInfo = ClientPlData &
  SessionPlQueryReturnData & { emptyAccounts: boolean; nav: GenieNavPlData; error?: string };

// Used by checkRoutePermissions to check a route perm allowance against the current session
export const checkAllowance = (
  clientInfo: SessionPlQueryReturnData,
  routePermission: Perms,
  childKey?: keyof NavPermissions,
): boolean => {
  // Recursive function only allow 2 levels of nested entries
  const navPermissions =
    childKey && childKey !== null
      ? clientInfo.nav_permissions[childKey]
      : clientInfo.nav_permissions;

  if (!navPermissions) {
    return false;
  }

  const isRouteForAll = routePermission === '*';

  const isValidNavPermission =
    typeof navPermissions !== 'number' &&
    hasOwnProp(navPermissions, routePermission as PropertyKey);

  const isValidClientPermission =
    hasOwnProp(clientInfo.permissions, routePermission as PropertyKey) &&
    Boolean(clientInfo.permissions[routePermission as keyof typeof clientInfo.permissions]);

  // Check if permission is in navPermissions or clientInfo.permissions && is set to true/1
  if (isRouteForAll || isValidNavPermission || isValidClientPermission) {
    return true;
  }

  // if permissions we're checking for is an object, recurse through object and validate permission
  if (typeof navPermissions === 'object') {
    for (const key in navPermissions) {
      const permissionsKey = key as keyof NavPermissions;
      const navPermission = navPermissions[permissionsKey];
      if (navPermission && typeof navPermission === 'object' && !navPermission.shortcode) {
        // Recurse into children
        return checkAllowance(clientInfo, routePermission, permissionsKey);
      }
    }
  }

  return false;
};

// Used by checkPermissionByObject Fn to process permissions that contain multiple values
export const processPermissionValues = (
  clientInfoPermission: keyof ClientInfoPermissions,
  routePermission: string,
): boolean => {
  if (routePermission.includes('|')) {
    const checkPermissionValues = routePermission
      .split('|')
      .some((permission) => permission === clientInfoPermission);
    return checkPermissionValues;
  }
  return clientInfoPermission === routePermission;
};

// Used by checkRoutePermissions to check a route perm allowance against the current session if it's an object
export const checkPermissionByObject = (
  routePermissionsObject: Perms,
  clientInfoPermissions: ClientInfoPermissions,
): boolean =>
  Object.entries(routePermissionsObject).every(([key, value]) => {
    // if value is an object, recurse
    if (clientInfoPermissions && clientInfoPermissions[key] && isPlainObject(value)) {
      return checkPermissionByObject(value, clientInfoPermissions[key]);
    }
    return (
      clientInfoPermissions &&
      clientInfoPermissions[key] &&
      processPermissionValues(clientInfoPermissions[key], value)
    );
  });

// Used by setRoutePermissions to determine which method used to check if session matches route permission
export const checkRoutePermission = (
  clientInfo: SessionPlQueryReturnData,
  route: Partial<Route>,
) => {
  const permission = route.perm;

  // Bypass dashboard-splash page
  if (
    'path' in route &&
    route.path === '/dashboard' &&
    (clientInfo.isChild || clientInfo.isParent)
  ) {
    return true;
  }

  const isValidPermissionsObject =
    typeof permission === 'object' &&
    isPlainObject(permission) &&
    checkPermissionByObject(permission, clientInfo.permissions);

  const isValidPermissionString =
    typeof permission === 'string' &&
    !isPlainObject(permission) &&
    checkAllowance(clientInfo, permission);

  if (isValidPermissionsObject || isValidPermissionString) {
    return true;
  }

  return false;
};

// Used to add additional data to routes, determines which routes to be displayed
export const setRoutesPermissions = (processedRoutes: Route[], clientInfo: CollatedClientInfo) => {
  if (!clientInfo || clientInfo?.emptyAccounts) {
    return [];
  }

  if (clientInfo.error && (!clientInfo.nav || !clientInfo.permissions)) {
    Sentry.withScope((scope) => {
      scope.setTags({
        message: 'Error trying to setRoutePermissions',
        error: clientInfo?.error,
        file: 'admin.jsx',
        fn: 'setRoutePermissions()-01',
      });
      Sentry.captureException(new Error('setRoutesPermissions()'));
    });
  }

  return processedRoutes.map((route) => {
    const updatedRoute = { ...route };
    if (route.collapse && route.views) {
      let newViews = route.views;
      if (typeof route.views === 'function') {
        if (route.viewsProp === 'analytics') {
          newViews = route.views(clientInfo.nav.analytics);
        } else if (route.viewsProp === 'insightsNav') {
          newViews = route.views(clientInfo.insights_nav);
        } else {
          // We are handling a callback function to get data dynamically
          newViews = route.views(clientInfo);
        }
      }
      if (typeof newViews !== 'function') {
        updatedRoute.views = setRoutesPermissions(newViews, clientInfo);
      }
    }
    updatedRoute.allowed = checkRoutePermission(clientInfo, updatedRoute);
    return updatedRoute;
  });
};

// Determines the current active route
export const getActiveRoute = (processedRoutes: Route[]): string => {
  const defaultRoute = 'Dianomi';

  const findActiveRoute = (routes: Route[]): string => {
    for (const route of routes) {
      if (route.collapse && Array.isArray(route.views)) {
        const collapseActiveRoute = findActiveRoute(route.views);
        if (collapseActiveRoute) return collapseActiveRoute;
      } else if (route.path && route.name && window.location.href.includes(route.path)) {
        return route.name;
      }
    }
    return defaultRoute;
  };

  return findActiveRoute(processedRoutes);
};
